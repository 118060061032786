const projectSpecs = [
  {
    title: "Experimental Concert Research",
    marquee: { url: "images/ECR.png", height: "100px" },
    link: "/academic/ecr",
    description:
      "How do dramaturgical decisions influence perception and physiological reactions to the concert experience?",
    category: "academic",
    frontPage: true,
  },
  {
    title: "Urban Climates",
    marquee: { url: "images/urban-climates.png", height: "100px" },
    link: "/academic/urban-climates",
    description:
      "Can we use geo-spatial data to better understand the relationship between micro-climate\
    and the built environment?",
    category: "academic",
    frontPage: true,
  },
  {
    title: "Quantity Theory of Money Insanity",
    marquee: { url: "images/QTM.png", height: "100px" },
    link: "https://cramakrishnan.medium.com/the-quantity-theory-of-money-insanity-part-1-d75e2af6c620",
    description:
      "Does the Quantity Theory of Money actually explain inflation?",
    category: "article",
    frontPage: true,
  },
  {
    title: "DeLong-Shiller Redux",
    marquee: { url: "images/dsr.png", height: "100px" },
    link: "https://medium.com/@cramakrishnan/the-delong-shiller-redux-dc9dd21eefd1",
    description:
      "What do Robert Shiller's CAPE and the Efficient Market Hypothesis\
    tell us about stock-market returns?",
    category: "article",
    frontPage: true,
  },
  {
    title: "Outlier Year in Review",
    marquee: { url: "images/outlier-year-in-review.png", height: "140px" },
    link: "http://outlier.illposed.com/articles/2022review",
    description:
      "What did Outlier drop in 2022, and how the brand has evolved over the years?",
    category: "article",
    frontPage: false,
  },
  {
    title: "Clothesography",
    marquee: { url: "images/oia.svg", height: "100px" },
    link: "http://outlier.illposed.com/",
    description: "Explore the clothes and objects made by Outlier.",
    category: "interactive",
    frontPage: true,
  },
  {
    title: "Every Vessel",
    marquee: { url: "images/2017-05-23-01.png", height: "150px" },
    link: "http://ev.illposed.com/",
    description:
      "Digital music is so intangible. \
    Can we use technology to bring back the physicality of \
    a record in a sleeve with cover art?",
    category: "article",
    frontPage: true,
  },
  {
    title: "KCRW",
    marquee: { url: "images/DJMomentaryFavs.png", height: "200px" },
    link: "http://kcrw.illposed.com",
    description: "How do the DJs at KCRW influence one another?",
    category: "interactive",
    frontPage: true,
  },
];

export default projectSpecs;
