import React from "react";
import Card from "react-bootstrap/Card";
import Masonry from "react-masonry-component";
import { Link } from "react-router-dom";

import Markdown from "../../features/markdown";
import { ExternalLink, Spinner } from "../../utils";
import ProjectSpecs from "./ProjectSpecs";

function projectMarqueeElement(project, imgHeight = null) {
  const marquee = project["marquee"];
  if (marquee["url"] == null) return null;
  if (marquee["url"] === "") return null;
  const props = {};
  if (imgHeight != null) props["height"] = imgHeight;
  else if (marquee["height"] != null) props["height"] = marquee["height"];
  else props["width"] = "100%";

  return (
    <div className="text-center">
      <img
        className="pb-3"
        alt={project["title"]}
        src={marquee["url"]}
        {...props}
      />
    </div>
  );
}

function ProjectCard({ project, index }) {
  const marquee = projectMarqueeElement(project);
  return (
    <Card
      className="project-card"
      border="light m-3"
      style={{ width: "18rem" }}
      bg="light"
      key={index}
    >
      <Card.Body>
        {marquee}
        <Card.Title>
          <ExternalLink href={project["link"]}>{project["title"]}</ExternalLink>
        </Card.Title>
        <Card.Text>{project["description"]}</Card.Text>
      </Card.Body>
    </Card>
  );
}

function InternalFrontPageProjectCard({ project, index, imgHeight }) {
  const marquee = projectMarqueeElement(project, imgHeight);
  return (
    <Card
      className="project-card"
      border="light m-3"
      style={{ minWidth: "6rem", width: "10rem" }}
      bg="light"
      key={index}
    >
      <Link to={project["link"]}>
        <Card.Body>
          {marquee}
          <Card.Text
            className="w-100 text-center"
            style={{ fontFamily: "Jost" }}
          >
            {project["title"]}
          </Card.Text>
        </Card.Body>
      </Link>
    </Card>
  );
}

function ExternalFrontPageProjectCard({ project, index, imgHeight }) {
  const marquee = projectMarqueeElement(project, imgHeight);
  return (
    <Card
      className="project-card"
      border="light m-3"
      style={{ minWidth: "6rem", width: "10rem" }}
      bg="light"
      key={index}
    >
      <ExternalLink href={project["link"]}>
        <Card.Body>
          {marquee}
          <Card.Text
            className="w-100 text-center"
            style={{ fontFamily: "Jost" }}
          >
            {project["title"]}
          </Card.Text>
        </Card.Body>
      </ExternalLink>
    </Card>
  );
}

function FrontPageProjectCard({ project, index, imgHeight }) {
  if (project.category === "academic") {
    return (
      <InternalFrontPageProjectCard
        project={project}
        index={index}
        imgHeight={imgHeight}
      />
    );
  }
  return (
    <ExternalFrontPageProjectCard
      project={project}
      index={index}
      imgHeight={imgHeight}
    />
  );
}

function ProjectList({ projects }) {
  return (
    <Masonry>
      {projects.map((p, i) => ProjectCard({ project: p, index: i }))}
    </Masonry>
  );
}

function FrontPageProjects(props) {
  const projects = ProjectSpecs.filter((p) => p["frontPage"]);
  return (
    <div className="d-flex flex-wrap">
      {projects.map((p, i) => (
        <FrontPageProjectCard key={i} project={p} index={i} imgHeight="100px" />
      ))}
    </div>
  );
}

function Academic({ projects }) {
  return (
    <>
      <h2 id="academic">Academic</h2>
      <ProjectList projects={projects} />
    </>
  );
}

function DataAnalysis({ projects }) {
  return (
    <>
      <h2>Data Analysis</h2>
      <ProjectList projects={projects} />
    </>
  );
}

function InteractiveViz({ projects }) {
  return (
    <>
      <h2>Interactive Visualizations</h2>
      <ProjectList projects={projects} />
    </>
  );
}

function Projects() {
  const academicProjects = ProjectSpecs.filter(
    (p) => p["category"] === "academic"
  );
  const analysisProjects = ProjectSpecs.filter(
    (p) => p["category"] === "article"
  );
  const interactiveProjects = ProjectSpecs.filter(
    (p) => p["category"] === "interactive"
  );
  const spinner = <Spinner />;
  return (
    <>
      <div className="d-md-flex">
        <div className="pe-md-3">
          <h1>Projects</h1>
        </div>
        <div className="pt-md-2">
          <figure>
            <blockquote className="blockquote">
              Far better an approximate answer to the right question, which is
              often vague, than an exact answer to the wrong question, which can
              always be made precise.
            </blockquote>
            <figcaption className="blockquote-footer text-end pb-4">
              John Tukey,{" "}
              <cite title="The Future of Data Analysis">
                The Future of Data Analysis
              </cite>
            </figcaption>
          </figure>
          <Markdown source="pages/projects.md" spinner={spinner} />
        </div>
      </div>
      <Academic projects={academicProjects} />
      <DataAnalysis projects={analysisProjects} />
      <InteractiveViz projects={interactiveProjects} />
    </>
  );
}

export default Projects;

export { FrontPageProjects };
