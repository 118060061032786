import React from "react";

import { ExternalLink } from "../../utils";

function UrbanClimates() {
  return (
    <>
      <div className="d-md-flex">
        <div className="pe-md-3">
          <h1>UrbanClimates</h1>
        </div>
        <div className="pt-md-2">
          <blockquote className="blockquote">
            Can we use geo-spatial data to better understand the relationship
            between micro-climate and the built environment?
          </blockquote>
        </div>
      </div>
      <div className="markdown pt-md-2 pb-2">
        <p>
          The{" "}
          <ExternalLink href="https://birkhauser.com/books/9783035624243">
            Coping with Urban Climates
          </ExternalLink>{" "}
          project interweaves socio-cultural with meteorological data to explore
          the architectural and technological as well as legal and economic
          dimensions of climate control in urban environments.
        </p>
        <p>To assist this project, I contributed the following:</p>
        <ul>
          <li>
            <b>Geo-referencing</b> and extraction of quantitative information to
            create GIS data sets from images
          </li>
          <li>
            <b>Summarizing</b> quantitative data to support comparisons between
            geographic regions
          </li>
          <li>
            <b>Data visualizations</b> to view information both spatially and
            quantitatively
          </li>
        </ul>
        <img
          src="/images/academic/urban-climates-spread.jpg"
          className="w-100"
        />
      </div>
    </>
  );
}

export default UrbanClimates;
