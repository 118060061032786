import React from "react";

import "./spinner.css";

// Spinner from https://loading.io/css/
function Spinner({ className }) {
  const cl = (className) ? `lds-ring ${className}` : "lds-ring";
  return <div className={cl} role="status">
    <div></div><div></div><div></div><div></div>
    <span className="visually-hidden">Loading...</span>
  </div>;
}

export default Spinner;
