import React from "react";
// Spinner from https://loading.io/css/
function ExternalLink({ children, href }) {
  return (
    <a href={href} target="_blank" rel="noreferrer noopener">
      {children}
    </a>
  );
}

export default ExternalLink;
