import React from "react";

import { ExternalLink } from "../../utils";

function ECR() {
  return (
    <>
      <div className="d-md-flex">
        <div className="pe-md-3">
          <h1>ECR</h1>
        </div>
        <div className="pt-md-2">
          <blockquote className="blockquote">
            How do dramaturgical decisions influence perception and
            physiological reactions to the concert experience?
          </blockquote>
        </div>
      </div>
      <div className="markdown pt-md-2 pb-2">
        <p>
          The{" "}
          <ExternalLink href="https://experimental-concert-research.org/">
            Experimental Concert Research
          </ExternalLink>{" "}
          project investigates connections between concert dramaturgy how
          attendees react to the concert. My contributions included the
          following:
        </p>
        <ul>
          <li>
            <b>Digital signal processing (DSP)</b> to extract more salient
            variables from the low-level output of the physiological measurement
            devices (e.g., convert the blood-volume pulse (BVP) to heart rate)
          </li>
          <li>
            <b>Statistical analysis</b> of differences in variables between
            concert settings and socio-demographic groups
          </li>
          <li>
            <b>Data visualizations</b> to understand data at the different
            scales, from low-level signals at the millisecond level to
            aggregations over concerts and groups.
          </li>
        </ul>
        <img src="/images/academic/ecr-spread.png" className="w-100" />
      </div>
    </>
  );
}

export default ECR;
