import React, { Fragment } from "react";
import { Github, Linkedin, Twitter } from "react-bootstrap-icons";

import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

import Markdown from "../../features/markdown";
import Spinner from "../../utils/spinner";

function NavItem(props) {
  const to = props.href;
  const title = props.title;
  return (
    <Nav.Item>
      <Nav.Link to={to} as={Link}>
        {title}
      </Nav.Link>
    </Nav.Item>
  );
}

function Contact() {
  return (
    <div style={{ fontSize: "24px" }} className="d-flex">
      <div className="pe-2">
        <a href="https://github.com/ciyer">
          <Github />
        </a>
      </div>
      <div className="pe-2">
        <a href="https://twitter.com/ciyer">
          <Twitter />
        </a>
      </div>
      <div className="pe-2">
        <a href="https://www.linkedin.com/in/cramakrishnan/">
          <Linkedin />
        </a>
      </div>
    </div>
  );
}

function History() {
  return (
    <div className="d-md-flex markdown">
      <div className="pe-md-3">
        <h4>Historical Content</h4>
      </div>
      <div className="pt-md-2">
        <p>
          Illposed has been online for over 20 years. Some content from the past
          is no longer representative of current activities, but is still
          referenced from other sites and may still be of interest to some.
        </p>
        <Nav>
          <NavItem
            href="/philosophy/pataprogramming.html"
            title="'Pataprogramming"
          />
          <NavItem href="/software/javaosc.html" title="JavaOSC" />
        </Nav>
      </div>
    </div>
  );
}

function About() {
  const spinner = <Spinner />;
  return (
    <>
      <div className="d-md-flex">
        <div className="pe-md-3">
          <h1>About</h1>
        </div>
        <div className="pt-md-2">
          <Markdown source="pages/about.md" spinner={spinner} />
          <Contact />
        </div>
      </div>
      <div style={{ margin: "50px 0px" }}></div>
      <History />
    </>
  );
}

export default About;
