import React from "react";
import logo from "./logo.svg";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink as RRNavLink,
} from "react-router-dom";

import About from "./pages/about";
import { ECR, UrbanClimates } from "./pages/academic";
import Music from "./pages/music";
import Projects, { FrontPageProjects } from "./pages/projects";
import Spinner from "./utils/spinner";
import Markdown from "./features/markdown";

function NavItem(props) {
  const to = props.href;
  const title = props.title;
  return (
    <Nav.Item>
      <Nav.Link exact to={to} as={RRNavLink}>
        {title}
      </Nav.Link>
    </Nav.Item>
  );
}

function AppNav(props) {
  return (
    <div id="app-nav">
      <Nav variant="pills" defaultActiveKey="/" className="flex-md-column">
        <NavItem href="/" title="Illposed" />
        <NavItem href="/projects" title="Projects" />
        <NavItem href="/music" title="Music" />
        <NavItem href="/about" title="About" />
      </Nav>
    </div>
  );
}

function AppSplash(props) {
  return (
    <header>
      <div style={{ textAlign: "center", letterSpacing: "0.25em", width: 360 }}>
        data | analysis | visualization
      </div>
      <img className="me-2 mb-5" width="360" src={logo} alt="illposed logo" />
      <div className="ms-3">
        <p>
          Real world problems are <b>illposed</b>.
        </p>
      </div>
      <div>
        <FrontPageProjects />
      </div>
    </header>
  );
}

function AppFooter(props) {
  return (
    <footer className="footer mt-auto py-3 bg-light">
      <span className="text-muted mx-2">
        &copy; Illposed {new Date().getFullYear()}
      </span>
    </footer>
  );
}

function App() {
  const spinner = <Spinner />;
  return (
    <Router>
      <main className="flex-shrink-0 mx-md-5">
        <Container fluid>
          <Row className="pt-5">
            <Col
              id="content"
              xs={{ span: 12, order: 1 }}
              md={{ span: 8, order: 0 }}
              style={{ maxWidth: "800px" }}
            >
              <Switch>
                <Route exact path="/">
                  <AppSplash />
                </Route>
                <Route exact path="/projects">
                  <Projects />
                </Route>
                <Route exact path="/academic">
                  <Projects />
                </Route>
                <Route exact path="/academic/ecr">
                  <ECR />
                </Route>
                <Route exact path="/academic/urban-climates">
                  <UrbanClimates />
                </Route>
                <Route exact path="/music">
                  <Music />
                </Route>
                <Route exact path="/about">
                  <About />
                </Route>
                <Route exact path="/philosophy/pataprogramming.html">
                  <Markdown
                    source="/pages/pataprogramming.md"
                    spinner={spinner}
                  />
                </Route>
                <Route exact path="/software/javaosc.html">
                  <Markdown source="/pages/javaosc.md" spinner={spinner} />
                </Route>
              </Switch>
            </Col>
            <Col
              xs={{ span: 12, order: 0 }}
              md={{ span: 4, order: 1 }}
              className="mb-3 mb-md-0"
            >
              <Route>
                <AppNav />
              </Route>
            </Col>
          </Row>
        </Container>
      </main>
      <Route>
        <AppFooter />
      </Route>
    </Router>
  );
}

export default App;
